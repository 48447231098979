import React, {FC, useEffect, useState} from 'react';
import Footer from "./Footer";
import Header from "./Header";
import Popup from "./Popup";
import ChatManager from "./ChatManager";
import RegisterPush from "../pages/RegisterPush";

type PropsLayout = {
    children: any,
};

const Layout: FC<PropsLayout> = ({children}) => {
    const [platform, setPlatform] = useState<number | undefined>(1);
    const [lsPush,setLsPush] = useState<string | null>("");
    useEffect(() => {
        setLsPush(window.localStorage.getItem("push"));
    }, []);

    useEffect(() => {
        if (navigator.platform.indexOf("Mac") === 0 || navigator.platform === "iPhone") {
            setPlatform(0)
        }
    }, []);
    useEffect(() => {

    }, [platform]);
    return (
        <div className="layout">
            <div className="layout__svg">
            </div>
            {!lsPush && platform !== 0 &&
                <RegisterPush/>
            }
            <Header/>
            <Popup/>
            <ChatManager/>
            <main className="main">
                {children}
            </main>
            <Footer/>
        </div>
    );
};

export default Layout;