import React from 'react';

const LogoutIcon = () => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_359_921)">
                <path
                    d="M2.1011 0H11.556C12.1133 0 12.6477 0.231785 13.0417 0.644365C13.4358 1.05694 13.6571 1.61652 13.6571 2.2V3.3C13.6571 3.59174 13.5465 3.87153 13.3494 4.07782C13.1524 4.28411 12.8852 4.4 12.6066 4.4C12.328 4.4 12.0608 4.28411 11.8637 4.07782C11.6667 3.87153 11.556 3.59174 11.556 3.3V2.2H2.1011V19.8H11.556V18.7C11.556 18.4083 11.6667 18.1285 11.8637 17.9222C12.0608 17.7159 12.328 17.6 12.6066 17.6C12.8852 17.6 13.1524 17.7159 13.3494 17.9222C13.5465 18.1285 13.6571 18.4083 13.6571 18.7V19.8C13.6571 20.3835 13.4358 20.9431 13.0417 21.3556C12.6477 21.7682 12.1133 22 11.556 22H2.1011C1.54385 22 1.00943 21.7682 0.615397 21.3556C0.221365 20.9431 0 20.3835 0 19.8V2.2C0 1.61652 0.221365 1.05694 0.615397 0.644365C1.00943 0.231785 1.54385 0 2.1011 0Z"
                    fill="#000057"/>
                <path
                    d="M13.4417 15.7245C13.8514 16.1535 14.5133 16.1535 14.923 15.7245L18.6924 11.7777C18.8893 11.5714 18.9999 11.2917 18.9999 11C18.9999 10.7083 18.8893 10.4286 18.6924 10.2223L14.923 6.27553C14.7233 6.08654 14.4619 5.98512 14.1928 5.99223C13.9237 5.99935 13.6675 6.11445 13.4772 6.31374C13.2868 6.51304 13.1769 6.78129 13.1701 7.06305C13.1633 7.34481 13.2602 7.61853 13.4407 7.82763L15.4115 9.90003H6.30323C6.02461 9.90003 5.7574 10.0159 5.56038 10.2222C5.36337 10.4285 5.25269 10.7083 5.25269 11C5.25269 11.2918 5.36337 11.5716 5.56038 11.7778C5.7574 11.9841 6.02461 12.1 6.30323 12.1H15.4115L13.4407 14.1724C13.2446 14.3786 13.1345 14.6577 13.1347 14.9487C13.1349 15.2397 13.2453 15.5187 13.4417 15.7245Z"
                    fill="#000057"/>
            </g>
            <defs>
                <clipPath id="clip0_359_921">
                    <rect width="19" height="22" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default LogoutIcon;