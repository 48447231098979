import React from 'react';
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__sub__container">
                <h3 className="footer__sub__title__number">18+</h3>
                <p className="footer__text__copyright">COPYRIGHT © 2023
                    CLUB CASINO</p>
            </div>
            <div className="footer__sub__container footer__sub__vidmova">
                <h3 className="footer__sub__title">ВІДМОВА ВІД ВІДПОВІДАЛЬНОСТІ</h3>
                <p className="footer__text">Ви повинні переконуватись, що дотримуєтесь усіх вікових та інших нормативних
                    вимог перед тим як увійти у казино або поставити ставку. інформація на цьому сайті представлена
                    тільки для гравців у казино.</p>
            </div>
            <div className="footer__sub__container footer__sub__gra ">
                <h3 className="footer__sub__title">ВІДПОВІДАЛЬНА ГРА</h3>
                <p className="footer__text">Сайт Club Casino не проводить ігри на гроші і не несе відповідальності ні за
                    які втрати від гри в казино. Ми завжди закликаємо до використання відповідної азартної гри.
                </p>
            </div>
            <div className="footer__sub__container footer__unsubscribe__link__container">
                <NavLink exact to="/unsubscribe" className="footer__unsubscribe__link">
                    Відмовитись від розсилань
                </NavLink>
            </div>
        </footer>
    );
};

export default Footer;
