import React, {useEffect} from 'react';
import General from "../pages/General";
import useDefaults from "../hooks/useDefaults";
import useOffers from "../hooks/useOffers";


const ContainerGeneral = () => {
    const {defaults} = useDefaults();
    const {offers, setInitialOffers} = useOffers();

    useEffect(() => {
        if (!defaults?.showOffers) return;
        setInitialOffers("showOffers").then(() => {
        })
    }, [defaults]);

    const likeSetOffersShowOffers = () => {
        setInitialOffers("showOffers").then(() => {
        });
    }

    return (
        <General state={offers} updateOffers={likeSetOffersShowOffers}/>
    );
};

export default ContainerGeneral;
