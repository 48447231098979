import React, {useState} from 'react';
import RestorePassword from "../pages/RestorePassword";
import {useQueryParams} from "../Components/QueryParams";
import api from "../api";
import useAuth from "../hooks/useAuth";
import {Link} from "react-router-dom";

enum RestorePasswordStates {
    Pending = "PENDING",
    Success = "SUCCESS",
    Error = "ERROR",
}

const ContainerRestorePassword = () => {
    const [restore, setRestore] = useState<RestorePasswordStates>(RestorePasswordStates.Pending);

    const {rt} = useQueryParams();
    const {authorize, checkAuth} = useAuth();

    const handleRestore = (newPassword: string) => {
        if (!rt) {
            setRestore(RestorePasswordStates.Error);
            return
        }

        api.users.restorePassword({
            newPassword,
            resetPasswordToken: rt,
        })
            .then(async ({token}) => {
                authorize(token);
                await checkAuth();
                setRestore(RestorePasswordStates.Success);
            })
            .catch((e) => setRestore(RestorePasswordStates.Error))
    }
    return (
        <>
            {restore === RestorePasswordStates.Pending && <RestorePassword onRestore={handleRestore}/>}
            {restore === RestorePasswordStates.Success &&
                <div className="offer__none__container">
                    <p className="offer__none__text">Ви змінили пароль і ввійшли. Перейдіть за головним посиланням тут</p>
                    <Link className="offer__none__link" to="/">На головну</Link>
                </div>
            }
            {restore === RestorePasswordStates.Error &&
                <div className="offer__none__container">
                    <p className="offer__none__title">От дідько !</p>
                    <p className="offer__none__text">Щось пішло не так, спробуйте будь-ласка пізніше</p>
                </div>}
        </>
    );
};

export default ContainerRestorePassword;