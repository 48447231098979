import React, {useEffect} from 'react';
import Promokod from "../pages/Promokod";
import useOffers from "../hooks/useOffers";
import useDefaults from "../hooks/useDefaults";

const ContainerPromokod = () => {
    const {offers, setInitialOffers} = useOffers();
    const {defaults} = useDefaults();

    useEffect(() => {
        if(!defaults?.showOffers) return;
        setInitialOffers("showPromo").then( () => {});
    }, [defaults]);
    const likeSetOffersShowAll = () => {
        setInitialOffers("showPromo").then( () => {});
    }

    return (
        <>
            <Promokod state={offers} updateOffers={likeSetOffersShowAll}/>
        </>
    );
};

export default ContainerPromokod;