import {useCallback, useContext} from "react";
import {GlobalContext} from "../context/GlobalContext";
import api from "../api";

const useDefaults = () => {
    const {gs, updateGS} = useContext(GlobalContext);

    const getDefaults = useCallback(async () => {
        const defs = await api.getDefaults();
        updateGS('defaults', defs)
    }, []);

    return {
        defaults: gs?.defaults ?? {},
        getDefaults,
    }
}

export default useDefaults;