import React from 'react';
import Profile from "../pages/Profile";
import useAuth from "../hooks/useAuth";
import {Redirect} from "react-router-dom";

const ContainerProfile = () => {
    const {logout, isAuth, user} = useAuth();
    if (!isAuth) return <Redirect to='/'/>
    return (
        <>
            {isAuth && <Profile user={user} logout={logout}/>}
        </>
    );
};

export default ContainerProfile;