import React, { useEffect} from 'react';
import AllCasino from "../pages/AllCasino";
import useOffers from "../hooks/useOffers";
import useDefaults from "../hooks/useDefaults";

const ContainerAllCasino = () => {
    const {offers, setInitialOffers} = useOffers();
    const {defaults} = useDefaults();
    useEffect(() => {
        if(!defaults?.showOffers) return;
        setInitialOffers("showAll").then( () => {});
    }, [defaults]);
    const likeSetOffersShowAll = () => {
        setInitialOffers("showAll").then( () => {});
    }
    return (
       <AllCasino state={offers} updateOffers={likeSetOffersShowAll}/>
    );
};

export default ContainerAllCasino;