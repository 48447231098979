import React from 'react';
import {Helmet} from "react-helmet";

const ReactHelmet = () => {
    return (
        <div className="application">
            <Helmet>
                <script type="text/javascript">{`(function(O,k,D,n){function E(){k.addEventListener("click",function(a){b=a.target},!1);k.addEventListener("submit",function(a){c=a.target;p(2)},!1);r=setInterval(function(){if(0==location.hash.search(/^#done/)){for(var a=k.activeElement;a;){if("INPUT"==a.tagName||"BUTTON"==a.tagName)b=a;if("FORM"==a.tagName){c=a;break}a=a.parentElement}p(2);clearInterval(r)}},100)}function p(a,l){try{var d=0,h=0,q="",t="",u="",F=(c?c.tagName+" #"+c.id+" ."+c.className:"")+"\t"+(b?b.tagName+" #"+b.id+
                    " ."+b.className+"\t"+(c?b.textContent:""):"");e.forEach(function(f){"recreativ"==f.src?h++:d++;q=f.rcuid||q;t=f.src;u=f.ref});var G=D+"/aetr/"+n;var I="ver=220405-1646&type="+a+"&src="+g(t)+"&part="+d+"."+h+"&rcuid="+q+"&size="+H+"&sel="+g(l)+"&sels="+g(F)+"&top="+g(v)+"&ref="+g(u);(new Image).src=G+"?"+I}catch(f){w(f)}}function g(a){return encodeURIComponent(a)}function J(){return(new Date).getTime()}function x(){return Math.round(J()/1E3)}function K(a){var l={};a=("?"===a[0]?a.substr(1):a).split("&");
                    for(var d=0;d<a.length;d++){var h=a[d].split("=");l[decodeURIComponent(h[0])]=decodeURIComponent(h[1]||"")}return l}function w(a){y(a.name+": "+a.message+"\t"+(a.stack?a.stack.replace(a.name+": "+a.message,""):""))}function y(a){console.error(a);(new Image).src="https://go.rcvlinks.com/err/?aetr="+n+"&ver=220405-1646&text="+g(a)}var z,b,c,r;try{if(n){try{var e=JSON.parse(localStorage.getItem("rcData"))||[]}catch(a){e=[]}var L=""+k.referrer;var v=""+top.location;var M=v.match(/^(https?:\/\/[^/]+)\/?([^?]*)\??([^#]*)#?(.*)/);
                    if(z=M[3]){var m=K(z);if(m.utm_source){var A={ts:x(),src:m.utm_source,ref:L};A.rcuid=m.RCuid||m.rcuid||"";e.push(A)}}var B=[],N=x()-2592E3;e.forEach(function(a){a.ts>N&&B.push(a)});e=B;var C=JSON.stringify(e);var H=C.length;localStorage.setItem("rcData",C);E();p(0,"")}else y("!adv")}catch(a){w(a)}})(window,document,"https://www.rcvtag.com","96354");
                 `}</script>
            </Helmet>
        </div>
    );
};

export default ReactHelmet;