import React from 'react';

const BurgerIcons = () => {
    return (
        <svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="29" height="3" rx="1.5" fill="#000057"/>
            <rect y="9" width="29" height="3" rx="1.5" fill="#000057"/>
            <rect y="18" width="29" height="3" rx="1.5" fill="#000057"/>
        </svg>
    );
};

export default BurgerIcons;